.full-screen-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(60.07deg, #01206d 15.98%, #020a1f 79.88%), #ffffff;
  overflow-y: auto;
  margin-top: 10rem !important;
}
.mod {
  margin-left: 5rem;
}

.modal-overlayll {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  z-index: 999;
  justify-content: center;
  align-items: center;
}

.modalll {
  background: linear-gradient(60.07deg, #01206d 15.98%, #020a1f 79.88%), #ffffff;
  padding: 20px;
  border-radius: 5px;
  max-width: 90%;
  max-height: 80%;
  position: relative;
}
.modal-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(70vh - 50px);
}

.a-link {
  color: var(--White, #f2f2f2);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.24px;
  text-decoration-line: underline;
}

.tab-heading {
  color: #fff;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
  margin-bottom: 15px;
  margin-top: 10px;
}

.tab-lable {
  color: #fff;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
  margin-bottom: 8px;
}

.forgot-password-link {
  position: absolute;
  color: var(--Bck, #edcd6a);
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.26px;
  cursor: pointer;
}

.banner__contentbox--text {
  color: #fff;
  font-family: "Montserrat";
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
}

.banner__contentbox--text-popup {
  color: var(--White, #f2f2f2);
  text-align: center;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.banner__contentbox--title {
  font-family: "Montserrat";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.phoneinput {
  padding-left: 65px;
  color: #fff;
  font-family: "Source Code Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}

.input-container {
  position: relative;
}

.resend_otp {
  color: var(--White, #f2f2f2);
  text-align: center;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.24px;
}

.phoneicon {
  position: absolute;
  top: 39px;
  margin-left: 12px;
}

.edit_number {
  color: #edcd6a;
  display: inline-flex;
  flex-direction: row;
  text-align: center;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.24px;
  position: absolute;
  /* bottom: 15px; */
  right: 25px;
  cursor: pointer;
}

.defaultvalue {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-family: "Source Code Pro", monospace;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.26px;
  margin-left: 5px;
}

.iconPosition {
  position: absolute;
  /* margin-bottom: 1.5px; */
  left: 1.6rem;
}

.otp-input-box {
  width: 45px !important;
  height: 45px;
  font-size: 1.2em;
  text-align: center;
  margin-right: 10px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.6);
  margin-right: 20px;
  color: #fff;
  outline: none !important;
  border: 2px solid #736b42;
}

/* .otp-input-box:hover {
  border-color: #b6a238 !important;
} */

.otp-error-strick {
  border-color: #e16767;
}

.highlight:valid {
  border-color: #b6a238;
}

/* Style for inactive OTP input */
/* .otp-input-box:not(:focus) { */
/* border-color: gray; Change border color for inactive input */
/* } */
/* input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px transparent inset #fff !important;
} */

.card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 48px;
  margin-top: 50px;
}

.card1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20px;
  gap: 20px;
  border: 1px solid var(--Bck, #edcd6a);
  width: 15rem !important;
  padding: 25px;
  height: 15rem;
  cursor: pointer;
  background-image: radial-gradient(#333740, rgb(33, 33, 33));
  /* background: var(
    --Radial,
    radial-gradient(174.64% 160.39% at 50.4% 58%, rgba(255, 249, 215, 0.21) 0%, rgba(255, 255, 255, 0.11) 100%)
  ); */
  box-shadow: 0px 21px 21px 0px rgba(0, 0, 0, 0.34), 0px 47px 28px 0px rgba(0, 0, 0, 0.2),
    0px 83px 33px 0px rgba(0, 0, 0, 0.06), 0px 130px 36px 0px rgba(0, 0, 0, 0.01), 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px -2px 5px 0px rgba(0, 0, 0, 0.47), 0px -9px 9px 0px rgba(0, 0, 0, 0.4), 0px -21px 13px 0px rgba(0, 0, 0, 0.24);
}

.card1:hover {
  background-image: radial-gradient(#424651, rgb(43, 42, 42));
}

.crdtitle-arrow-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card-h1 {
  color: var(--Bck, #edcd6a);
  text-align: center;
  font-family: "Montserrat";
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.56px;
}

.card-img {
  height: 5rem;
  width: 5rem;
  margin-top: 2rem;
}

.arrow-right {
  margin: 0px 0px 6px 5px;
}

.TC-container {
  min-height: 100%;
  min-width: 100%;
  background: linear-gradient(60deg, #01206d 15.98%, #020a1f 79.88%);
}

.tc-heading {
  color: var(--White, #f2f2f2);
  text-align: left;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.tc-para {
  color: var(--White, #f2f2f2);
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
  letter-spacing: 0.21px;
}

.tc-title {
  color: var(--White, #f2f2f2);
  font-family: "Montserrat";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  text-wrap: nowrap;
}

.container_t {
  display: flex;
  align-items: center;
  /* Vertically center items */
  justify-content: center;
  /* Horizontally center items */

  position: sticky;
  top: 0;
  z-index: 9999; /* Ensure header is above content */
  padding: 10px;
}

.arrow {
  position: absolute;
  left: 0;
  width: 35px;
  cursor: pointer;
}

.create-ac-link-web {
  display: none;
}

.create-ac-link {
  bottom: 10px;
}

.cubes {
  display: flex !important;
  justify-content: space-between !important;
}
.member--inputbox:disabled {
  border-color: #f7d41a;
  background: rgba(0, 0, 0, 0.619);
}
.hr-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--White, #f2f2f2);
  text-align: center;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  .hr-line {
    border: 1px solid #f2f2f2;
    width: 45%;
  }
}

.change_password {
  color: var(--Bck, #edcd6a);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.56px;
  text-transform: capitalize;
  text-decoration: none;
}

.change_password:hover {
  color: var(--Bck, #edcd6a);
}

.ord-1 {
  display: none;
}

.ord-2 {
  display: block;
}

.gard--textflow {
  font-size: 14px;
  margin: 1rem 0 0.7rem 4px;
}

.create-acoount {
  margin: 0px !important;
}

.profile-logo {
  color: #f2f2f2;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  /* 23.4px */
}

.resend-btn {
  background-color: transparent;
  border: none;
  outline: none;
  position: absolute;
  color: #edcd6a;
  text-align: center;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.24px;
  right: 0.2rem;
  top: 1rem;
  /* bottom: 1rem; */
}
.board__top--heading-mob {
  margin-left: 1rem;
}

.board__top--heading-mob {
  margin-left: 1rem;
}
.nav-bottom {
  display: none;
}

/* tool tip*/

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  background-color: #fff;
  padding: 8px;
  border-radius: 4px;
  animation: tooltipFadeDown 0.3s ease forwards;
  color: var(--Selection-Color-2, #274681);
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 80% */
  letter-spacing: 0.2px;
}

.call-icon {
  width: 1rem;
  margin-right: 5px;
}

.arrow-down {
  position: absolute;
  top: -8px;
  right: 10px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
}

.TCcontainer {
  padding: 1rem;
  margin: 1rem;
}

.border-highlight {
  /* Add your border highlighting styles here */
  border: 1px solid #edcd6a;
  /* Change color or style as needed */
  /* You can add more styles for highlighting */
}

.grade-drop {
  color: #ffffffb2;
}

.modal-container1 {
  position: relative;
  min-width: 400px;
  max-width: 80vw;
  min-height: 200px;
  /* border: 1px solid var(--ifl--primary); */
  border-radius: 1rem;
  background: #141c2b;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
}

.correct-img {
  width: 6rem;
}

.t-c_class {
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
}
.loader-gif {
  width: 2.5rem;
  margin-right: 0.6rem;
}
.loader-text {
  color: #241111;
  font-weight: bold;
  margin-top: 1rem;
}
.loader-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.back_login {
  justify-content: center;
  align-items: center;
  padding: 1.05rem 2.25rem;
  line-height: 1;
  margin-top: 0rem;
  text-decoration: none;
  background: linear-gradient(91.08deg, #b6a238 0.48%, #eddc82 44.37%, #a89637 99.62%);
  border-radius: 50px;
  color: #3c3306;
}
.back_login:hover {
  background: var(--Bck, #edcd6a);
  color: #3c3306;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  /* -webkit-box-shadow: 0 0 0px 1000px #00000068 inset; */
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: white;
}
.custom-table {
  border-collapse: collapse;
  width: 100%;
}

.custom-table th,
.custom-table td {
  border: 2px solid #edcd6a; /* Border style and color */
  padding: 8px; /* Adjust padding as needed */
  text-align: left; /* Adjust text alignment as needed */
}
.para-bold {
  font-weight: bold;
}
.profile_name_text {
  color: var(--White, #f2f2f2);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
}
.profileb-grade-text {
  color: var(--White, #f2f2f2);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}
.personal-details {
  color: var(--White, #f2f2f2) !important;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Montserrat;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  text-transform: capitalize;
}
.error-text {
  color: var(--Error-State, #f24e4e);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
}
.rotate {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.rotate-180 {
  transform: rotate(180deg);
}
.alertBar-cont {
  width: 100%;
  display: flex;
}
.alertbarImage {
  width: 100%;
}
.alertClose {
  position: absolute;
  right: 1%;
  width: 1.5rem;
  cursor: pointer;
}

@keyframes tooltipFadeDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 480px) {
  .banner__contentbox--title {
    font-family: "Montserrat";
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    /* 26px */
  }

  .banner__contentbox--text {
    font-size: 12px !important;
    line-height: 150%;
    /* 18px */
  }

  .tab-heading {
    font-size: 14px;
    letter-spacing: 0.32px;
    margin-bottom: 5px;
  }

  .tab-lable {
    letter-spacing: 0.26px;
    font-size: 13px;
  }

  .create-ac-link {
    display: none;
  }

  .gard--textflow-mob {
    margin-left: 5px;
  }

  p {
    margin-bottom: 0 !important;
  }

  .login--supporttext {
    margin: 2rem 0rem 1rem 0rem;
  }

  .gard--textflow-p {
    color: var(--Bck, #edcd6a);
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .create-ac-link-web {
    display: block;
  }

  .card-container {
    justify-content: space-around;
    margin-top: 1rem;
    gap: 20px;
  }

  .card1 {
    gap: 10px;
    width: 160px !important;
    height: 140px !important;
  }

  .card-h1 {
    font-size: 0.7rem !important;
  }

  .membership__card_mob {
    height: 42rem;
    height: fit-content;
  }

  .card-img {
    height: 3rem !important;
    width: 3rem !important;
    margin-top: 0.3rem;
  }

  .arrow-right {
    margin: 0px 0px 6px 5px;
    width: 20px;
    height: 20px;
  }

  .resend-btn {
    right: 2px;
  }

  .otp-input-box {
    margin-right: 12px;
  }

  .white__layerbg {
    background: linear-gradient(170deg, rgba(226, 124, 40, 0.493) 20%, rgb(61 43 28 / 0%) 53%);
    top: -155px;
  }

  .Student_name {
    display: none !important;
  }

  .board__upcoming__box {
    padding: 0;
  }

  .profile-img {
    margin-left: 6rem;
    margin-top: 0.6rem;
    width: 2rem;
    height: 2rem;
  }

  .phone-icon {
    display: block;
    margin-left: 0.3rem;
    margin-top: 1rem !important;
  }

  .nav-bottom {
    width: 100%;
    height: 2.2rem;
    background: var(--Selection-Color-2, #274681);
    display: inline-flex;
    padding: 1px 186px 1px 16px;
    align-items: center;
    position: fixed;
    z-index: 2;
  }

  .nav-bottom-container {
    display: flex;
    align-items: center;
  }

  .admission-id {
    color: var(--White, #f2f2f2);
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
    margin-top: 0rem;
  }

  .login-id {
    color: var(--White, #f2f2f2);
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    margin-top: 0rem;
  }

  .copy-btn_b {
    background-color: transparent;
    margin-left: 0.6rem;
  }

  .copy-btn_svg {
    width: 20px !important;
    height: 20px !important;
  }

  .ord-1 {
    display: block;
  }

  .ord-2 {
    display: none;
  }

  .navbar-brand_mob {
    top: 16px !important;
  }

  .TCcontainer {
    padding: 0.5rem;
    margin: 0.5rem;
  }

  .tc-title {
    font-size: 16px;
    text-wrap: nowrap;
  }

  .tc-para {
    color: var(--White, #f2f2f2);
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
    letter-spacing: 0.21px;
  }

  .tc-heading {
    font-size: 14px;
  }

  .arrow {
    bottom: 8px;
    width: 20px;
  }
}

@media screen and (max-width: 360px) {
  .t-c_class {
    font-size: 10px;
  }
}

@media screen and (max-width: 768px) {
  .banner__contentbox--title {
    font-family: "Montserrat";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    /* 26px */
  }

  .banner__contentbox--text {
    font-size: 20px;
    line-height: 150%;
    /* 18px */
    margin-bottom: 15px;
  }

  .tab-heading {
    font-size: 16px;
    letter-spacing: 0.32px;
    margin-bottom: 5px;
  }

  .tab-lable {
    letter-spacing: 0.26px;
    font-size: 13px;
  }

  .gard--textflow {
    font-size: 14px;
  }

  .create-ac-link {
    display: none;
  }

  .gard--textflow-mob {
    margin-left: 5px;
  }

  p {
    margin-bottom: 0 !important;
  }

  .login--supporttext {
    margin: 1rem 0rem 1rem 0rem;
  }

  .gard--textflow-p {
    color: var(--Bck, #edcd6a);
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .create-ac-link-web {
    display: block;
  }

  .board__upcoming__box {
    padding-left: 0rem;
  }

  .Student_name {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .banner__contentbox--title {
    font-family: "Montserrat";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    /* 26px */
  }

  .banner__contentbox--text {
    font-size: 20px;
    line-height: 150%;
    /* 18px */
    margin-bottom: 15px;
  }

  .tab-heading {
    font-size: 16px;
    letter-spacing: 0.32px;
    margin-bottom: 5px;
  }

  .tab-lable {
    letter-spacing: 0.26px;
    font-size: 13px;
  }

  .gard--textflow {
    font-size: 14px;
  }

  .create-ac-link {
    display: none;
  }

  .gard--textflow-mob {
    margin-left: 5px;
  }

  p {
    margin-bottom: 0 !important;
  }

  .login--supporttext {
    margin: 1rem 0rem 1rem 0rem;
  }

  .gard--textflow-p {
    color: var(--Bck, #edcd6a);
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .create-ac-link-web {
    display: block;
  }
}

@media screen and (max-width: 340px) {
  .card1 {
    width: 136px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 400px) {
  .tc-title {
    font-size: 14px;
    text-wrap: nowrap;
  }
  .modalll {
    height: 95%;
  }
  .modal-content {
    max-height: calc(70vh - 50px);
  }
}
@media only screen and (min-width: 220px) and (max-width: 480px) {
  .modal-container1 {
    min-width: 270px !important;
  }
  .correct-img {
    width: 3rem;
  }
  .banner__contentbox--text-popup {
    font-size: 1rem !important;
  }
  .board__top--heading-mob {
    margin-top: 1.5rem;
    margin-left: 1rem;
  }
}

@media only screen and (min-width: 400px) and (max-width: 470px) {
  .TCcontainer {
    padding: 0.5rem;
    margin: 0.5rem;
  }

  .tc-title {
    font-size: 20px;
    text-wrap: nowrap;
  }
  .tc-heading {
    font-size: 18px;
  }
  .modalll {
    height: 90%;
  }
  .modal-content {
    max-height: calc(70vh - 50px);
  }
}

@media only screen and (min-width: 480px) and (max-width: 780px) {
  .tc-title {
    font-size: 20px;
    text-wrap: nowrap;
  }
  .board__top--heading-mob {
    margin-top: 1.5rem;
    margin-left: 1rem;
  }
}

@media only screen and (min-width: 780px) and (max-width: 1280px) {
  .TCcontainer {
    padding: 1rem 2rem;
    margin: 1rem 2rem;
  }

  .tc-title {
    font-size: 30px;
    text-wrap: nowrap;
  }
}

@media only screen and (min-width: 320px) and (max-width: 380px) {
  .resend_otp {
    margin-left: 0.5rem;
    letter-spacing: 0px;
  }

  .tc-title {
    font-size: 14px;
    text-wrap: nowrap;
  }
}

@media only screen and (min-width: 380px) and (max-width: 395px) {
  .resend_otp {
    margin-left: 1rem;
    letter-spacing: 0px;
  }

  .tc-title {
    font-size: 14px;
    text-wrap: nowrap;
  }
}

@media only screen and (min-width: 395px) and (max-width: 410px) {
  .resend_otp {
    margin-left: 2rem;
    letter-spacing: 0px;
  }

  .tc-title {
    font-size: 14px;
    text-wrap: nowrap;
  }
}

@media only screen and (min-width: 410px) and (max-width: 440px) {
  .resend_otp {
    margin-left: 3rem;
    letter-spacing: 0px;
  }
}

@media only screen and (min-width: 441px) and (max-width: 480px) {
  .resend_otp {
    margin-left: 5rem;
    letter-spacing: 0px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 993px) {
  .resend_otp {
    margin-left: 4rem;
    letter-spacing: 0px;
  }
  .modalll {
    height: 90%;
  }
  .modal-content {
    max-height: calc(70vh - 50px);
  }
  .TCcontainer {
    padding: 1rem;
    margin: 1rem;
  }

  .tc-title {
    font-size: 30px;
    text-wrap: nowrap;
  }
  .loader-text {
    margin-top: 0rem;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .resend_otp {
    margin-left: 0rem;
    letter-spacing: 0px;
  }
  .create-acoount {
    font-size: 13px !important;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1800px) {
  .resend_otp {
    margin-left: 4rem;
    letter-spacing: 0px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 335px) {
  .gard--textflow {
    font-size: 13px !important;
  }
}
@media only screen and (min-width: 220px) and (max-width: 480px) {
  .loader-text {
    margin-top: 0rem;
  }
  .view__detailbar-btn {
    font-size: 12px;
    padding: 14px 20px;
  }
  .profileb-grade-text {
    font-size: 12px;
  }
  .profile_name_text {
    font-size: 16px;
  }
  .personal-details {
    font-size: 14px;
  }
}
@media only Screen and (min-width: 320px) and (max-width: 360px) {
  .otp-input-box {
    margin-right: 4px;
  }
  .resend-btn {
    font-size: 10px;
    right: 1;
  }
}

/* reportpage */

.Rep_btn-cont {
  display: flex;
  justify-content: space-between;
}
.Reploader {
  width: 20rem;
  height: 20rem;
}
.Rep_back-btn {
  display: inline-flex;
  padding: 14px 20px;
  align-items: center;
  gap: 8px;
  border-radius: 55px;
  border: 1px solid var(--Color-Dark-Grey-500, #263643);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
  color: var(--Color-Dark-Grey-500, #263643);
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.12px;
  text-transform: uppercase;
}

.Rep_exam-title {
  color: var(--Grayscale-Title-Active, #14142b);
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 39px */
  margin: 1.6rem 0rem;
}
.Rep_sub-head {
  color: var(--Grayscale-Title-Active, #14142b);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 2rem 0rem;
}
.Rep_score_card {
  position: relative;
  width: 49%;
  height: 180px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid var(--Secondary-Darkmode, #ffed9d);
  background: var(--Secondary-Light, #fff7d4);
  padding: 1rem;
  /* card shadow */
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
}
.Rep_score_card2 {
  display: flex;
}
.rep_card_vector {
  position: absolute;
  top: 1px;
  right: 1px;
}
.Rep_score_cardinner {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 55%;
  height: 75px;
  padding: 0px 40px;
}
.Rep_score_cardinner img {
  width: 3rem;
}
.Rep_score_inner_title {
  color: var(--grayscale-label-icons-grey, #6e7191);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
}
.Rep_score_inner_marks {
  color: var(--Grayscale-Body, #4e4b66);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
}
.saparator_v {
  position: absolute;
  background-color: #d9dbe9;
  width: 2px;
  height: 100%;
  right: 50%;
  top: 0;
}
.saparator_h {
  position: absolute;
  background-color: #d9dbe9;
  width: 100%;
  height: 2px;
  top: 50%;
  right: 0;
}
.Rep_score_cardHead {
  color: var(--grayscale-label-icons-grey, #6e7191);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
}
.Rep_score_cardDes {
  color: var(--Grayscale-Body, #4e4b66);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
}
.Rep_score_cardButtom {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.Rep_score_cardLine {
  width: 100%;
}
.Rep_graph_card {
  width: 49%;
  height: 180px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  padding: 1rem;
  /* card shadow */
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
}
.Rep_card-cont {
  display: flex;
  justify-content: space-between;
}
.Rep_graph_cardHead {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Rep_graph_cont {
  display: flex;
  justify-content: space-between;
}

.Rep_lable_cont {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}
.Rep_lable_contIn {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.Rep_lable_contIn img {
  margin-bottom: 1rem;
}
.Rep_Sub_cont {
  margin: 1rem 0rem;
  width: 100%;
  max-height: 238px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  padding: 1rem;
  /* card shadow */
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
}

.cetrificate-cont {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 13px;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #d9dbe9;
  background: #fff;
  margin-bottom: 1rem;
}
.mock-para {
  color: var(--Grayscale-Title-Active, #14142b);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}
.Rep_Sub_contSubject {
  color: #4e4b66;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}
.Rep_Sub_contIn {
  display: flex;
  flex-wrap: wrap;
}
.Rep_Sub_cont-card {
  width: 50%;
}

.progressbar {
  width: 340px;
  height: 20px;
  border-radius: 20px;
  background: var(--Grayscale-Input-Background, #eff0f6);
}
.progress_filler {
  height: 20px;
  border-radius: 20px;
  background: var(--Secondary-Default, #fbd323);
  display: flex;
  justify-content: center;
  text-align: center;
}
.EMRepprogress_filler {
  height: 20px;
  border-radius: 20px;
  background: var(--Secondary-Default, #fbd323);
  overflow: visible;
}
.EMRepprogress_INfiller {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.EMRepprogress_INfiller img {
  height: 180px;
}
.negitive_progress {
  color: var(--Error-Default, #b7295a);
  font-family: Montserrat;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  margin-top: 2px;
}
.Rep_progressor-cont {
  display: flex;
  gap: 1rem;
}
.tab_subject {
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  opacity: 0.5;
  border: none;
  background-color: transparent;
  margin-bottom: 1rem;
  height: 3rem;
}
.selected_tab {
  opacity: 1;
  border-bottom: 2px solid #3c8dcb;
  margin-right: 0.6rem;
}
.rep_select {
  display: flex;
  width: 336px;
  margin: 1rem 0rem;
  height: 45px;
  flex-shrink: 0;
  border-radius: 32px;
  padding: 0rem 1.3rem;
  background: #fff;
  border: none;
  box-shadow: 0px 4px 77px 0px rgba(0, 0, 0, 0.16);
  appearance: none;
  justify-content: space-between;
  align-items: center;
}

.rep_select_option {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 1rem;
  position: absolute;
  z-index: 2;
  width: 191px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 77px 0px rgba(0, 0, 0, 0.05);
}
.rep_select_option h4 {
  color: var(--Grayscale-Body, #4e4b66);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.rep_select_option h4:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}
.Rep_timer-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 326px;
  border-radius: 16px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  margin: 1.5rem 0;
  /* card shadow */
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
}
.Rep_bluredreport-cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 643px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  margin: 1.5rem 0;
  /* card shadow */
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
}
.Rep_paidTick {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
}
.Rep_bluredreport-cont h4 {
  color: var(--Grayscale-Title-Active, #14142b);
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;

  /* Mobile/Subtitle 1 - SB */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.Rep_price {
  color: var(--Primary-Default, #3c8dcb);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0px;
}
.Rep_discountPrice {
  color: var(--Grayscale-Body, #4e4b66);
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  text-decoration: line-through;
}
.Rep_discount {
  width: 60%;
  height: 18px;
  flex-shrink: 0;
  border-radius: 12.396px;
  background: var(--Secondary-Darkmode, #ffed9d);
  color: var(--Warning-Dark, #946200);
  text-align: center;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 3px;
  margin: 2px 1px;
}
.Rep_timer-timecont {
  color: var(--Grayscale-Body, #4e4b66);
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  margin-bottom: 2px;
}
.Rep_sampleReportCTA {
  color: var(--Primary-Default, #3c8dcb);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  text-decoration: underline;
  cursor: pointer;
  margin-top: 1.5rem;
}
.Rep_timer-cont img {
  width: 116px;
  height: 116px;
  flex-shrink: 0;
  margin-bottom: 1rem;
}
.Rep_sandtimer {
  width: 116px;
  height: 116px;
  flex-shrink: 0;
  margin-bottom: 1rem;
  border-radius: 58px;
}
.Rep_timer-cont h1 {
  color: var(--Grayscale-Title-Active, #14142b);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 109.091% */
}
.Rep_timer-des {
  color: var(--Grayscale-Body, #4e4b66);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}
.Rep_checkoutbtn {
  display: flex;
  width: 100%;
  padding: 12px 64px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 12px;
  background: var(--primary-60, #007bff);
  box-shadow: 0px 7px 7px 0px rgba(0, 123, 255, 0.12);
  color: var(--Background-02, #fff);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: lowercase;
  border: none;
  text-wrap: nowrap;
}
.Rep_timer-des span {
  font-weight: 700;
}
.ReportLoaderCont {
  display: flex;
  width: 100%;
  height: 80vh;
  justify-content: center;
}
.Rep_timer-time {
  color: var(--Error-Default, #b7295a);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.Rep_Paidtimer-time {
  color: var(--IL-primary-color, #3c8dcb);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 20.8px */
}
.Rep_score_card3 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.mock_imgweb {
  display: block;
}
.mock_imgmob {
  display: none;
}
/* Popup Overlay */
.Rep-popup-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  overflow: auto;
}
.Rep-popup-cont {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(24, 23, 44, 0.811);
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.Rep-popup-overlay.pdf-mode {
  background: none; /* No background in PDF mode */
  scrollbar-width: none;
}

/* Popup Content */
.Rep-popup-content {
  background: #fff;
  padding: 26px;
  border-radius: 30px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05); /* Card shadow */
  max-width: 80%;
  max-height: 80%;
  overflow: auto; /* Make the content scrollable if it's too large */
  scrollbar-width: none;
}

.pdf-content {
  background: none; /* No background in PDF mode */
  border: none; /* No border in PDF mode */
  border-radius: 0; /* No border radius in PDF mode */
  width: 100%; /* Full width in PDF mode */
  height: 100vh; /* Full screen height for PDF mode */
  overflow-y: auto !important;
  scrollbar-width: none !important;
  background: transparent;
}
.Rep-popup-overlay:-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

/* Optional Drop Shadow for Children */
.Rep-child-element {
  fill: var(--Grayscale-Title-Active, #14142b);
  opacity: 0.8;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05); /* Optional drop shadow */
}
.Rep_PackageReviwPopup {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.Rep_loader_cont {
  position: absolute;
  width: 10rem;
  top: 39%;
  left: 45%;
}
.Rep_packagecont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.Rep_packagecont h4 {
  color: var(--Black, #000);
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
}
.Rep_packageName {
  display: flex;
  width: 48%;
  padding: 18px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  flex-shrink: 0;
  border-radius: 16px;
  background: var(--Secondary-Darkmode, #ffed9d);
}
.Rep_packagePricing {
  display: flex;
  width: 48%;
  padding: 18px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
}
.Rep_packagePricing h5 {
  color: var(--Grayscale-Title-Active, #14142b);
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;
}
.Rep_packageName h5 {
  color: var(--Grayscale-Body, #4e4b66);
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 30px */
  letter-spacing: 0.4px;
}
.Rep_packageName p {
  color: var(--Warning-Dark, #946200);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 100% */
  letter-spacing: 0.28px;
  margin-bottom: 0px;
}
.Rep_packagecontInner {
  width: 75%;
  display: flex;
  gap: 1rem;
  border-radius: 20px;
  background: #fff;
  padding: 12px;
  /* Shadow 1 */
  box-shadow: 0px 4px 20px 0px rgba(0, 131, 232, 0.2);
}
.Rep_line {
  width: 1px;
  height: 108px;
  background: #d9dbe9;
}
.Rep_BillingCont {
  width: 100%;
  display: flex;
  gap: 1rem;
}
.Rep_Billinginfo {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 30px */
  letter-spacing: 0.4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 20.8px */
  padding: 0rem 2rem 0rem 0rem;
}
.Rep_BillinginfoIN {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.Rep_BillingSummary {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.Rep_BillingSummaryCont {
  width: 100%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  gap: 8px;
  border-radius: 20px;
  border: 1px solid var(--neutral-30, #cecfd0);
  background: #f1f2f6;
}
.Rep_BillingSummaryCont {
  color: var(--neutral-100, #080e14);

  /* Body 03/Medium */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-transform: lowercase;
}
.Rep_SuparatorHline {
  height: 1px;
  width: 100%;

  background: var(--neutral-30, #cecfd0);
}
.Rep_Subotal {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.blurred {
  filter: blur(8px);
  pointer-events: none; /* Disable interactions while blurred */
  position: relative;
}
.Rep_Subotal p {
  color: var(--Dark-Dark-3, #4f4f4f);
  margin-bottom: 0;
  /* Caption/Medium */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
}
.Rep_Subotal h5 {
  color: #00354e;
  text-align: right;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}
.Rep_Subotal h6 {
  color: var(--neutral-100, #080e14);

  /* Body 03/Medium */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-transform: lowercase;
}
.Rep_flotingfablecont {
  width: 100%;
  display: flex;
  position: relative;
}
.Rep_flotingfable {
  display: flex;
  width: inherit;
  padding: 8px 18px;
  align-items: flex-start;
  gap: 4.26px;
  border-radius: 12px;
  border: 1px solid #52565b;
  background: var(--Background-02, #fff);
}
.Rep_flotingLable {
  color: #6b6e72;
  /* Caption/Regular */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  position: absolute;
  background-color: #fff;
  width: initial;
  border-radius: 5px;
  top: -9px;
  padding: 0px 1px;
}
.Rep_countryCode {
  width: 40px;
  color: var(--neutral-100, #080e14);
  border-right: solid 1px #e6e7e8;
  /* Body 03/Regular */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-transform: lowercase;
  margin-top: 1px;
  margin-bottom: 0px;
  border-radius: 1px;
}
.Rep_flotingLable span {
  color: red;
}
.Rep_flotingLableinput:focus {
  outline: none;
}
.Rep_flotingLableinput {
  outline: none !important;
  border: none;
  color: var(--neutral-100, #080e14);
  width: 100%;
  /* Body 03/Regular */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-transform: lowercase;
}
.Rep_testimonialsCont {
  width: inherit;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
}
.Rep_testimonialTextCont {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Rep_testimonialText {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}
.Rep_testimonialBy {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: end;
  cursor: pointer;
}
.Rep_faildIcon {
  width: 59px;
  height: 59px;
  align-self: center;
  gap: 0px;
  opacity: 0px;
}
.Rep_faildtitle {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.26px;
  text-align: center;
}
.Rep_failddes {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
  text-align: center;
  color: #db6864;
}
.Rep_faildAmountdes {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
  text-align: center;
}

/* Define animation for rotating the arrow */
@keyframes rotateUp {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-180deg);
  }
}

@keyframes rotateDown {
  from {
    transform: rotate(-180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

/* Apply rotation animation when isOpen is true */
.rotate-up {
  animation: rotateUp 0.3s linear forwards;
}

/* Apply rotation animation when isOpen is false */
.rotate-down {
  animation: rotateDown 0.3s linear forwards;
}

/* Ensure the image doesn't change its position during rotation */
.arrow-img {
  width: 12px; /* Adjust the width as needed */
  height: auto;
}
.Rep_progress {
  color: var(--Grayscale-Body, #4e4b66);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Rep_back-btn2 {
  display: none;
}
.Rep_main_cont {
  width: 95%;
}
.circle-cont1 {
  display: block;
}
.circle-cont2 {
  display: none;
}
.Rep-text-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
@media (max-width: 768px) {
  .Rep_btn-cont {
    align-items: center;
    width: 375px;
    height: 48px;
    flex-shrink: 0;
  }
  .Rep_back-btn {
    display: none;
  }
  .Rep_back-btn2 {
    display: block;
  }
  .Rep_exam-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  .cetrificate-cont {
    flex-direction: column;
    width: 100%;
    padding: 1rem;
  }
  .Rep_main_cont {
    max-width: 100vw;
    overflow-x: hidden;
    width: 100%;
  }
  .Rep_attempt-btn1 {
    display: flex;
    color: var(--IL-primary-color, #3c8dcb);
    align-items: flex-end;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.12px;
    text-transform: uppercase;
    background-color: transparent;
    text-wrap: nowrap;
  }
  .Rep_card-cont {
    flex-direction: column;
    gap: 1rem;
  }
  .Rep_score_card {
    width: 100%;
  }
  .Rep_graph_card {
    width: 100%;
    height: 162px;
  }
  .Rep_lable_title {
    font-size: 14px;
  }
  .Rep_lable_contIn img {
    margin-bottom: 0rem;
  }
  .Rep_Sub_cont-card {
    width: 100%;
  }
  .Rep_Sub_cont {
    max-height: 285px;
  }
  .Rep_graph_cont {
    margin-top: 1rem;
    font-size: 14px;
  }
  .Rep_lable_contIn {
    gap: 0.23rem;
  }
  .circle-cont1 {
    display: none;
  }
  .circle-cont2 {
    display: block;
  }
  .Rep_attempt-btn2 {
    align-self: start;
    width: 188px;
    font-size: 12px;
  }
  .Rep_score_card3 {
    gap: 2.3rem;
  }
  .Rep_back-btn2In {
    display: flex;
    gap: 1rem;
    color: #000;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .mock_imgweb {
    display: none;
  }
  .mock_imgmob {
    display: block;
    width: 5.5rem;
  }
  .Rep_mock-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.25px;
  }
  .Rep_mock-Cont {
    width: 180px;
    margin-bottom: 0.6rem;
  }
  .Rep_mock-para {
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 127.273% */
  }
  .Rep-text-cont {
    width: 100%;
    gap: 0px;
    justify-content: space-between;
    position: relative;
  }
  .selected_tab {
    border-radius: 8px 8px 0px 0px;
    border-top: 0.6px solid var(--Grayscale-Line, #d9dbe9);
    border-right: 0.6px solid var(--Grayscale-Line, #d9dbe9);
    border-left: 0.6px solid var(--Grayscale-Line, #d9dbe9);
    background: var(--Grayscale-Background, #f7f7fc);
    padding: 1rem;

    border-bottom: 0px;
  }
  .tab_subject {
    font-size: 16px;
  }
  .visibleblock {
    display: none;
  }
  .Rep_packagecontInner {
    width: 100%;
  }
  .Rep-popup-content {
    padding: 15px 10px;
    max-width: 100%;
  }
  .Rep_packagecont {
    gap: 0.5rem;
  }
  .Rep_packagecont h4 {
    font-size: 18px;
  }
  .Rep_BillingCont {
    flex-direction: column;
  }
  .Rep_Billinginfo {
    width: 100%;
  }
  .Rep_BillingSummary {
    width: 100%;
    gap: 1rem;
  }
  .Rep_Billinginfo {
    padding: 0px;
  }
  .Rep_discount {
    width: 100%;
  }
  .Rep_BillingSummaryCont {
    padding: 10px;
  }
  .Rep-popup-content {
    max-height: 90%;
  }
  .Rep_packageName h5 {
    font-size: 14px;
  }
  .Rep_packageName p {
    font-size: 12px;
  }
  .Rep_loader_cont {
    left: 34%;
  }
  .alertClose {
    top: 2%;
  }
}
@media only Screen and (min-width: 768px) and (max-width: 960px) {
  .Rep_main_cont {
    padding: 1.5rem 1rem;
  }
  .Rep_graph_cont {
    gap: 2rem;
  }
  .circle-cont1 {
    display: none;
  }
  .circle-cont2 {
    display: block;
  }
  .Rep_lable_contIn {
    gap: 0.23rem;
  }
  .Rep_lable_title {
    font-size: 14px;
  }
  .Rep_lable_cont {
    width: 65%;
  }
}

/*math reports*/

.MT-reports {
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--Grayscale-Line, #d9dbe9);
  background: var(--Primary-BG, #fafcff);

  /* Drop Shadow Small */
  box-shadow: 0px 8px 16px 0px rgba(17, 17, 17, 0.06);
}
.Mt-reportHeader {
  width: 100%;
  background: var(--Primary-Light, #e1f2ff);
  display: flex;
  color: var(--Grayscale-Title-Active, #14142b);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px; /* 212.5% */
  letter-spacing: 1px;
  padding-top: 1rem;
}
.Mt-SkillHeader {
  width: 25%;
  text-align: center;
}
.Mt-SubSkillHeader {
  width: 35%;
  text-align: center;
}
.Mt-ScoreHeader {
  width: 20%;
  text-align: center;
}
.Mt-StanineHeader {
  width: 20%;
  text-align: center;
}
.Mt-reportbody {
  display: flex;
  width: 100%;
  border-bottom: solid 1px #e2e2e2;
}
.Mt-Skillbody {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;

  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Mt-SubSkillcont {
  display: flex;
  flex-direction: column;
  width: 55%;
  text-align: center;
}
.Mt-subskillbody {
  width: 70%;
  color: #000;

  text-align: center;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
  align-self: center;
}
.Mt-inSkillcont {
  height: 3rem;
  display: flex;
  justify-content: center;
  width: 100%;
  border-bottom: solid 1px #e2e2e2;
}
.Mt-Scorebody {
  width: 30%;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-left: solid 1px #e2e2e2;
  padding-top: 1rem;
}
.Mt-Staninebody {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.MT-reportsGuide {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 22px 16px;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--Grayscale-Line, #d9dbe9);
  background: var(--Primary-BG, #fafcff);

  /* Drop Shadow Small */
  box-shadow: 0px 8px 16px 0px rgba(17, 17, 17, 0.06);
  margin: 1rem 0rem;
}
.MT-reportsGuide p {
  color: var(--Grayscale-Body, #4e4b66);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.MT-reportsGuide span {
  color: var(--Grayscale-Title-Active, #14142b);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.MT-reportsProfi {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.Mt-Sub-SkillHeader {
  width: 35%;
  text-align: center;
}
.Mt-medHeader {
  width: 25%;
  text-align: center;
}
.Mt-Sub-Skillbody {
  border: solid 1px #e2e2e2;
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Mt-medbody {
  border: solid 1px #e2e2e2;
  padding: 0.8rem;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Mt-medDotbody {
  width: 15px;
  height: 15px;
  border-radius: 10px;
}
.MT-reportsGuide img {
  width: 100%;
}
@media only Screen and (min-width: 320px) and (max-width: 768px) {
  .Mt-Skillbody {
    font-size: 10px;
  }
  .Mt-subskillbody {
    font-size: 10px;
  }
  .MT-reportsGuide p {
    font-size: 12px;
  }
  .MT-reportsGuide span {
    font-size: 13px;
  }
  .Mt-Sub-Skillbody {
    font-size: 10px;
  }
  .Rep_score_cardinner {
    display: flex;
    gap: 1rem;
    align-items: center;
    width: 55%;
    height: 54px;
    padding: 0px 23px;
  }
  .Rep_score_inner_title {
    font-size: 11px;
  }
  .Rep_score_inner_marks {
    font-size: 16px;
  }
}

/*EMsatReports*/

.EMsatRep_score_card {
  width: 100%;
  border-radius: 10px;
  background: #f6f5ee;
  padding: 2rem 2rem;
}
.EMsatRep_score_card p {
  color: var(--grayscale-label-icons-grey, #6e7191);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;

  display: flex;
}
.EMsatRep_score_carditem {
  display: flex;
  gap: 5px;
}
.EMsatRep_score_card_dot {
  text-align: center;
  align-content: center;
  width: 1.8rem;
}
.EMsatRep_score_card_phigh {
  color: var(--grayscale-label-icons-grey, #6e7191);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  height: 10px;
}
.EMRep_Sub_cont {
  margin: 1rem 0rem;
  width: 100%;
  height: 258px;
  flex-shrink: 0;
  display: flex;
  gap: 1rem;

  /* card shadow */
}
.EMRep_Sub_CirclerCont {
  width: 30%;
  height: 238px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  padding: 2px;
  /* card shadow */
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.EMRep_Sub_TrackCont {
  position: relative;
  width: 100%;
  flex-shrink: 0;
  height: 9rem;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  padding: 1rem;
  overflow: hidden;
  /* card shadow */
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem;
}

.EMRep_Sub_ClgTirCont {
  display: flex;
  flex-shrink: 0;
  height: 1rem;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;

  /* card shadow */
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 1.5rem 1rem;
}
.EMRep_Sub_ClgTirCont img {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 1.5rem;
}
.EMRep_Sub_ClgTirCont p {
  margin-top: 1rem;
}
.EMRep_Sub_contIn {
  width: 100%;
}

.EMRep_Sub_tripGraf {
  position: absolute;
  width: 93%;
  bottom: 2.2rem;
  left: 1.6rem;
  z-index: 1;
}
.EMRep_Sub_tripnuber0 {
  width: 19%;
  height: 100%;
  color: var(--Color-Dark-Grey-600, #1e2a36);
  text-align: end;
  align-content: end;
  font-family: Montserrat;
  font-size: 12.86px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 16.718px */
}
.EMRep_Sub_tripnuber300 {
  width: 80%;
  height: 100%;
  color: var(--Color-Dark-Grey-600, #1e2a36);
  text-align: end;
  align-content: end;
  font-family: Montserrat;
  font-size: 12.86px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.EMRep_Sub_tripnuber {
  width: 100%;
  display: flex;
  height: 100%;
}
.EMRep_Sub_tripyou {
  width: 100%;
  overflow-x: hidden;
}
.EMRep_Sub_tripuyouImg {
  position: absolute;
  top: 1.5rem;
  transition: left 1s ease-in-out;
  display: flex;
  flex-direction: column;
}
.EMRep_Sub_tripYou {
  z-index: 1;
}
.EMRep_Sub_tripuyouline {
  width: 4px;
  position: absolute;
  top: 55px;
  left: 48%;
  z-index: 0;
}
.EMRep_Sub_tripCesr {
  position: absolute;
  top: 4rem;
  width: 18px;
}
.EMRep_Sub_tripCeserCont {
  position: relative;
}
.EMRep_Sub_tripuCesline {
  width: 2.5px;
  position: absolute;
  top: 75px;
  left: 8px;
  z-index: 0;
}
.EMRep_score {
  color: #79bc82;
  text-align: center;
  font-family: Montserrat;
  font-size: 14.289px;
  font-style: normal;
  font-weight: 800;
  line-height: 130%; /* 18.575px */
  margin: 0;
}
.EMRep_cutoff {
  color: var(--Color-Dark-Grey-600, #1e2a36);
  text-align: center;
  font-family: Montserrat;
  font-size: 12.86px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 16.718px */
  margin-top: 3rem;
}
.EMRep_negtiveT {
  color: var(--Color-Dark-Grey-600, #1e2a36);
  text-align: center;
  font-family: Montserrat;
  font-size: 10.002px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 13.003px */
  width: 80px;
  position: absolute;
  top: 90px;
}
.EMRep_pai-chart-svg {
  margin-top: 1rem; /* Adjust this value as needed to move the SVG up */
}
.EMRep_chart-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: var(--grayscale-label-icons-grey, #6e7191);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.EMRep_chart-label span {
  color: var(--Grayscale-Body, #4e4b66);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.EMRe_info {
  width: 22px;
  height: 16px;
  border-radius: 15px;
  margin-bottom: 1rem;
  cursor: pointer;
}
.EMRep_chart-labelCont {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 0px;
}
.EMRep_chart-labelContPara {
  color: var(--grayscale-label-icons-grey, #6e7191);
  text-align: right;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.EMRep_chart-labelContPara span {
  color: var(--Grayscale-Body, #4e4b66);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 4px;
}
.EMRep_tooltipp {
  display: flex;
  position: absolute;
  top: calc(100% + 8px);
  right: -900%;
  transform: translateX(-50%);
  border-radius: 4px;
  animation: tooltipFadeDown 0.3s ease forwards;

  border: none;
}

.EMRep_tooltip {
  width: 420px;
  height: 112.501px;
  flex-shrink: 0;
  fill: #fff;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
  display: flex;
  flex-direction: column;
  text-align: end;
  padding: 0rem 0rem 0rem 6rem;
}
.EMRep_tooltippimg {
  width: 500px !important;
  height: 100px;
}
.EMRep_tooltip img {
  width: 15rem;
  align-self: end;
  margin-bottom: 0.8rem;
}

.EMRep_tooltip .arrow-down {
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff; /* Match tooltip background color */
}
.EMRep_graph_card {
  display: flex;
  justify-content: space-between;
  width: 49%;
  height: 214px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  padding: 1rem;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
}
.EMRep_timeProgress_card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 49%;
  height: 214px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  padding: 1rem;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}
.EMRep-column-timeprogress-textCont {
  display: flex;
}
.EMRep-timeprogress-dotcont {
  display: flex;
  margin-bottom: 0px;
  height: 25px;
  align-items: center;
}
.EMRep-column-timeprogress-textCont p {
  color: var(--Grayscale-Body, #4e4b66);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 26px */
  margin-bottom: 0px;
}
.EMRep-column-progress-textCont h1 {
  color: var(--Grayscale-Title-Active, #14142b);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.EMRep-column-progress-bar {
  width: 73px; /* Adjust the width as needed */
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 1.3rem;
  margin-left: auto;
  z-index: 1;
}

.EMRep-correct-section {
  background-color: #00ba88; /* Green for correct answers */
  transition: height 0.5s ease;
  z-index: 3;
  overflow-x: visible;
}

.EMRep-incorrect-section {
  background-color: #b7295a; /* Red for incorrect answers */
  transition: height 0.5s ease;
  z-index: 3;
  overflow-x: visible;
  display: flex;
  align-items: flex-end;
}
.EMRep-correctline {
  align-self: flex-end;
}
.EMRep-unAnsline {
  align-self: flex-start;
}
.EMRep-unanswered-section {
  transition: height 0.5s ease;
  border-radius: 4px 4px 0px 0px;
  background: var(--Grayscale-Line, #d9dbe9);
  z-index: 3;
  overflow-x: visible;
  display: flex;
  align-items: flex-end;
}
.EMRep-pointer_cont {
  color: var(--grayscale-label-icons-grey, #6e7191);
  text-align: right;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  gap: 8px;
  margin-bottom: 0px;
}
.EMRep-pointer_Qus {
  color: var(--Grayscale-Body, #4e4b66);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.EMRep-0 {
  border-top: solid 1px #a5aaaf;
  color: var(--Grayscale-Placeholder, #a0a3bd);
  text-align: end;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
}
.EMRep-100 {
  color: var(--Grayscale-Placeholder, #a0a3bd);
  text-align: end;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  position: absolute;
  top: 20px;
}
.EMRep-column-2Cont {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  gap: 10px;
}
.EMRep-column-2Cont1 {
  justify-content: space-between;
}
.EMRep-lineCont {
  position: absolute;
  z-index: 1;
  display: flex;
  right: 1px;
  gap: 1.4rem;
  width: 100%;
}

.EMRep-line1 {
  border: dashed 1px #a5aaaf;
  z-index: 0;
  height: 0px;
  width: 100%;
  border-radius: 2px;
  margin-left: 16px;
}
.EMRep-lineCont span {
  width: 10px;
  margin: 0px;
}
.EMRep-lineCont2 {
  padding-left: 2rem;
}
.EMRep-line2 {
  border: dashed 1px #a5aaaf;
  z-index: 0;
  height: 0px;
  width: 100%;
  border-radius: 2px;
  margin-left: 16px;
}
.EMRep-lineCont3 {
  padding-left: 4rem;
  align-self: flex-start;
}
.EMRep-line3 {
  border: dashed 1px #a5aaaf;
  z-index: 0;
  height: 0px;
  width: 100%;
  border-radius: 2px;
  margin-left: 16px;
}

.EMRep_progressbar {
  width: 100%;
  height: 20px;
  border-radius: 20px;
  background: var(--Grayscale-Input-Background, #eff0f6);
}
.EMRep_progress_filler {
  height: 14px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
}
.EMRep_progress_fillerCorret {
  background: #00ba88;
}
.EMRep_progress_filleriInCorret {
  background: #b7295a;
}
.EMRep_progress_fillerUnansr {
  background: #d9dbe9;
}
.EMRep_progres_container {
  width: 50%;
}
.EMRep_question_container {
  width: 50%;
}
.EMRep-column-progress-textCont2 {
  width: 50%;
  overflow: hidden;
}
.EMRep-pointer_cont1 {
  color: var(--grayscale-label-icons-grey, #6e7191);
  text-align: right;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  gap: 8px;
  margin-bottom: 0px;
  height: 36px;
}
.EMRep-verticalLine {
  width: 1px;
  background: #d9dbe9;
  border-radius: 1px;
}
.EMRep-subcontainer {
  width: 50%;
  display: flex;
  padding: 1rem;
  align-items: center;
}
.EMRep-subcontainer1 {
  width: 75%;
}
.EMRep-subcontainer2 {
  width: 25%;
  border-bottom: 1px solid #a5aaaf;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  height: 6rem;
}
.EMsat-barchart {
  background-color: #fbd323;
  width: 35px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.Emsat-Rep_Sub_cont {
  margin: 1rem 0rem;
  width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  padding: 1rem;
  /* card shadow */
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  display: flex;
}
.Emsat-Rep_Sub_contMob {
  display: none;
}
.EMRep-horydashedLine {
  border: 0.5px dashed #e7e8f1;
  width: 100%;
  margin-top: 0px;
}
.EMRep-value {
  color: var(--Grayscale-Body, #4e4b66);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 2px;
}
.EMRep-subcontainer1 img {
  margin: 0px 2px;
}
.EMRep-AvgText {
  margin: 3px 0px;
}
.EMRep-AvgTextCont {
  display: flex;
  justify-content: space-between;
}
.EMRep-TM-subcotainer {
  width: 50%;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  gap: 30px;
}
.EMRep-TM-heading {
  width: 210px;
  color: var(--Grayscale-Title-Active, #14142b);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.EMRep-TM-textCont {
  width: 100%;
}
.EMRep-TM-text {
  width: 220px;
  color: var(--grayscale-label-icons-grey, #6e7191);
  text-align: right;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  gap: 8px;
  margin-bottom: 0px;
}
.EMRep-TM-INertextCont {
  margin: 12px 0rem;
}
.EMRep-TM-barcont {
  width: 100%;
  border-bottom: 1px solid #a5aaaf;
  display: flex;
  align-items: flex-end;
  height: 10rem;
  justify-content: center;
  gap: 20px;
}
.EMRep-TM-secCont {
  display: flex;
  width: 50%;
}

.EMsat-barprogress {
  border-radius: 6px 6px 0px 0px;
  width: 14px;
  height: 33.406px;
}
.subjectwise-cont {
  width: 100%;
  display: flex;
  gap: 10px;
  margin: 10px 0px;
  align-items: center;
}
.EMRep_SubW_cont {
  margin: 1rem 0rem;
  width: 100%;

  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  padding: 1rem;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
}
.EMRep_ChapterW_cont {
  margin: 1rem 0rem;
  width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  padding: 1rem;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
}
.EMRep_SubW_contMob {
  display: none;
}

.subjectwise-name {
  width: 30%;
  color: var(--Color-Dark-Grey-500, #263643);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 0px;
}
.subjectwise-Score {
  width: 10%;
  color: #324452;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  gap: 10px;
  margin-bottom: 0px;
  align-items: center;
}
.subjectwise-Score span {
  color: var(--Color-Dark-Grey-600, #1e2a36);
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 19.5px */
}
.subjectwise-Progressbar {
  width: 35%;
  height: 10px;
  flex-shrink: 0;
  display: flex;
  border-radius: 15px;
  margin: 10px;
}
.subjectwise-Minuts {
  width: 20%;
  color: #324452;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  gap: 10px;
  margin-bottom: 0px;
  align-items: center;
}
.subjectwise-Minuts span {
  color: var(--Color-Dark-Grey-600, #1e2a36);
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 19.5px */
}
.EM-SDR-cnt {
  width: 100%;
  margin-top: 1rem;
}
.EM-SDR-btn-cnt {
  display: flex;
  gap: 20px;
  height: 4rem;
  overflow-x: scroll;
}

.EM-SDR-btn-cnt::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.EMRep_SubW_Headercont {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--Color-Dark-Grey-200, #748494);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 18px */
}
.EMRep_SubW_HeaderCapter {
  width: 25%;
}
.EMRep_SubW_Headerimp {
  width: 10%;
}
.EMRep_SubW_Headertotal {
  width: 15%;
}
.EMRep_SubW_HeaderAcur {
  width: 15%;
}
.EMRep_SubW_Headermarks {
  width: 15%;
}
.EMRep_SubW_HeaderPerf {
  width: 15%;
}
.EMRep_SubW_cardCont {
  display: flex;
  width: 100%;
  gap: 10px;
}
.EMRep_SubW_BodyCapter {
  width: 25%;
  color: #263643;
  text-overflow: ellipsis;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 161.538% */
}
.EMRep_SubW_Bodyimp {
  width: 10%;
  color: var(--Error-Dark, #a40038);
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 161.538% */
}
.EMRep_SubW_Bodytotal {
  width: 15%;
  color: #263643;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 161.538% */
}
.EMRep_SubW_BodyAcur {
  width: 15%;
  color: var(--Color-Dark-Grey-500, #263643);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  display: flex;
  gap: 5px;
  align-items: center;
}
.EMRep_SubW_Bodymarks {
  width: 15%;
  color: #263643;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 161.538% */
}
.EMRep_SubW_BodyPerf {
  width: 15%;
  color: var(--Success-Dark, #00966d);
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 161.538% */
}
.EMRep_SubW_BodyAcurBar {
  width: 60px;
  height: 3px;
  background-color: #e1f2ff;
  border-radius: 2px;
  display: flex;
  justify-content: left;
}
.EMRep_SubW_HeaderType {
  width: 35%;
}
.EMRep_SubW_BodyType {
  width: 35%;
  color: #263643;
  text-overflow: ellipsis;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 161.538% */
}
.EMRep_Area_cont {
  width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  padding: 1rem;

  /* card shadow */
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  gap: 10px;
}
.EMRep_Area_cont1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.EMRep_Area_cont1 h6 {
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.EMRep_Area_content {
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;
}
.EMRep_Area_content img {
  width: 30.181px;
  height: 30.126px;
}
.EMRep_Area_content p {
  color: var(--grayscale-label-icons-grey, #6e7191);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.EMRep_Area_content span {
  color: var(--grayscale-label-icons-grey, #6e7191);
  font-feature-settings: "liga" off, "clig" off;

  /* Mobile/Body 2 - M */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.EMRep_graph_HeadMob h6 {
  width: 100%;
  color: var(--Grayscale-Title-Active, #14142b);
  font-feature-settings: "liga" off, "clig" off;

  /* Mobile/Small 2 - M */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
.EMRep_graph_cardMob {
  display: none;
}
.EMRep_timeProgress_cardMob {
  display: none;
}
.EMRep_progres_containermob {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0rem 1rem 0rem 4rem;
}
@media only screen and (min-width: 280px) and (max-width: 780px) {
  .EMRe_info {
    margin-bottom: 0rem !important;
  }
  .EMRep_Sub_CirclerCont {
    width: 100%;
  }
  .EMRep_Sub_cont {
    flex-direction: column;
    height: 480px;
  }
  .EMRep_graph_card {
    width: 100%;
    display: none;
  }
  .EMRep_graph_cardMob {
    width: 100%;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid var(--card-stroke, #e7e8f1);
    background: #fff;
    padding: 1rem;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  }
  .EMRep_timeProgress_card {
    width: 100%;
    display: none;
  }
  .EMRep_timeProgress_cardMob {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid var(--card-stroke, #e7e8f1);
    background: #fff;
    padding: 1rem;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  }
  .EMRepprogress_INfiller img {
    height: 110px;
  }
  .EMsatRep_score_card p {
    color: var(--Grayscale-Body, #4e4b66);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.14px;
  }
  .Emsat-Rep_Sub_cont {
    flex-direction: column;
    display: none;
  }
  .EMRep-verticalLine {
    width: 100%;
    height: 1px;
  }

  .EMRep-TM-secCont {
    width: 100%;
  }
  .EMRep_tooltip {
    width: 300px;
  }
  .subjectwise-name img {
    display: none;
  }
  .EMRep_Sub_contIn {
  }
  .EMRep_Sub_contInmob {
    width: 100%;
  }
  .EMRep-pointer_cont1 {
    width: 50%;
    gap: 5px;
  }
  .EMRep_graph_PMobCont {
    display: flex;
    width: 100%;
    align-items: flex-start;
    align-content: flex-start;
    gap: 10px 16px;
    flex-wrap: wrap;
  }
  .EMRep_graph_PMobCont p {
    margin-bottom: 0px;
    color: var(--grayscale-label-icons-grey, #6e7191);
    text-align: right;
    font-feature-settings: "liga" off, "clig" off;

    /* Mobile/Small 3 - M */
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 127.273% */
    letter-spacing: 0.1px;
  }
  .EMRep_graph_PMobContIN {
    width: 100%;
    display: flex;
    gap: 10px;
  }
  .EMRep-column-progress-textCont2 {
    width: 100%;
  }
  .EMRep-100 {
    top: 2px;
  }
  .EMRep_progres_container {
    width: 100%;
  }
  .Emsat-Rep_Sub_contMob {
    margin: 1rem 0rem;
    width: 100%;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid var(--card-stroke, #e7e8f1);
    background: #fff;
    padding: 1rem;
    /* card shadow */
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
  }
  .EMRep_progres_container h1 {
    color: var(--Grayscale-Title-Active, #14142b);
    font-feature-settings: "liga" off, "clig" off;

    /* Mobile/Small 2 - M */
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
  }
  .EMRep_progres_container p {
    color: var(--Grayscale-Title-Active, #14142b);
    font-feature-settings: "liga" off, "clig" off;

    /* Mobile/Button - SB */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.25px;
  }
  .EMRep-AvgText {
    color: var(--grayscale-label-icons-grey, #6e7191);
    text-align: right;
    font-feature-settings: "liga" off, "clig" off;

    /* Mobile/Small 3 - M */
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 127.273% */
    letter-spacing: 0.1px;
  }
  .EMRep-AvgText span {
    color: var(--Grayscale-Body, #4e4b66);
    font-feature-settings: "liga" off, "clig" off;

    /* Mobile/Button - SB */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.25px;
  }
  .EMRep-subcontainer1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .EMRep-subcontainer {
    width: 100%;
    flex-direction: column;
    gap: 20px;
  }
  .EMRep-TM-subcotainer {
    width: 100%;
  }
  .EMRep-TM-secContMOb {
    flex-direction: column;
    margin: 1rem;
  }
  .EMsat-barprogress {
    width: 40px;
  }
  .EMRep-TM-barcont {
    height: 6rem;
  }
  .subjectwise-contMob {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
  }
  .subjectwise-inerMob {
    display: flex;
    gap: 5px;
    width: 100%;
    margin-bottom: 10px;
  }
  .subjectwise-Score {
    font-size: 11px;
    gap: 5px;
    width: 20%;
  }
  .subjectwise-Score span {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
  }
  .subjectwise-Progressbar {
    width: 40%;
  }
  .subjectwise-Minuts {
    width: 40%;
    display: flex;
    flex-direction: column;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    align-items: flex-start;
    gap: 2px;
  }
  .subjectwise-Minuts span {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
  }
  .subjectwise-vertLineMob {
    background: #d9dbe9;
    width: 100%;
    height: 1px;
    margin-bottom: 0.5rem;
  }
  .EMRep_Scroll {
    width: 986px;
  }
  .EMRep_SubW_cont {
    display: none;
  }
  .EMRep_SubScroll {
    width: 652px;
  }
  .EMRep_ChapterW_cont {
    overflow-x: scroll;
  }

  .EMRep_SubW_contMob {
    margin: 1rem 0rem;
    width: 100%;
    display: block;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid var(--card-stroke, #e7e8f1);
    background: #fff;
    padding: 1rem;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  }
  .EMRep_Area_cont {
    flex-direction: column;
    width: 100%;
    gap: 15px;
    margin: 10px 0px;
  }
  .EMRep_Area_cont1 {
    width: 100%;
  }
}

/* floting action buton */
.serviceIndex {
  position: fixed;
  bottom: 20%;
  right: 0.4%;
  z-index: 999;
}

.fab {
  background-color: #25d366; /* WhatsApp color */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.whatsappButton,
.callButton {
  background-color: #25d366; /* WhatsApp color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.callButton {
  background-color: #34b7f1; /* Call button color */
}
@media only screen and (min-width: 280px) and (max-width: 780px) {
  .serviceIndex {
    bottom: 15%;
  }
}
