/* PDF Viewer Styles */
.pdf-page {
  margin-bottom: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 4px;
}

.pdf-page:last-child {
  margin-bottom: 30px;
}

/* Fallback container when PDF rendering fails */
.pdf-fallback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 20px 0;
}

/* Download button for fallback */
.pdf-download-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #3c8dcb;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pdf-download-btn:hover {
  background-color: #357ab8;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .pdf-page {
    margin-bottom: 10px;
  }

  /* Ensure PDF fits within mobile viewport */
  .Rep-popup-content.pdf-content {
    width: 95% !important;
    padding: 10px !important;
  }

  /* Make buttons more touch-friendly on mobile */
  .pdf-controls button,
  .pdf-zoom-controls button,
  .pdf-download-btn {
    padding: 8px 16px !important;
    font-size: 14px !important;
    min-width: 80px;
  }

  /* Increase spacing for better touch targets */
  .pdf-controls,
  .pdf-zoom-controls {
    margin: 15px 0;
  }

  /* Ensure text is readable on mobile */
  .pdf-page-info,
  .pdf-zoom-info {
    font-size: 14px;
  }
}

/* Loading indicator */
.pdf-loading {
  color: #fff;
  text-align: center;
  padding: 20px;
  font-size: 16px;
}

/* Error message */
.pdf-error {
  color: #fff;
  text-align: center;
  padding: 20px;
  font-size: 16px;
  background-color: rgba(255, 0, 0, 0.1);
  border-radius: 4px;
}

/* Navigation controls for PDF */
.pdf-controls,
.pdf-zoom-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  color: #fff;
}

.pdf-zoom-controls {
  margin-bottom: 15px;
}

.pdf-controls button,
.pdf-zoom-controls button {
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pdf-controls button:hover,
.pdf-zoom-controls button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.pdf-controls button:disabled,
.pdf-zoom-controls button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pdf-page-info,
.pdf-zoom-info {
  margin: 0 10px;
  min-width: 80px;
  text-align: center;
}

/* Make sure the PDF container allows for zoomed content */
.Rep-popup-content.pdf-content {
  overflow: auto !important;
}

/* Add some space at the bottom of the PDF for better scrolling experience */
.pdf-page:last-child {
  margin-bottom: 50px;
}
